.partners {
  .slider-control {
    @include vp-767 {
      display: none;
    }
  }
}

.partners__slider {
  margin-top: size(-10px);
  margin-bottom: size(-10px);

  padding-top: size(10px);
  padding-bottom: size(10px);

  .slider-pagination {
    display: none;
  }

  @include vp-1279 {
    margin-top: -10px;
    margin-bottom: -10px;

    padding-top: 10px;
    padding-bottom: 10px;
  }

  @include vp-767 {
    padding-bottom: 48px;

    .slider-pagination {
      margin-top: 20px;

      display: flex;
      justify-content: center;
    }
  }
}
