.video-block {
  position: relative;
  display: flex;

  height: 100%;

  cursor: pointer;

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover,
    &:focus {
      .video-block__image-wrapper {
        img {
          transform: scale(1.1);
        }
      }

      .video-block__play {
        svg {
          opacity: 0.7;
        }
      }
    }
  }
}

.video-block__description {
  display: flex;
  align-items: center;
}

.video-block__title {
  display: flex;
  align-items: center;

  margin-top: 0;
  margin-right: size(12px);
  margin-bottom: 0;
  padding: size(16px);

  max-width: size(390px);
  width: 100%;
  height: 100%;

  font-weight: 700;
  font-size: size(16px);
  line-height: size(20px);
  text-transform: uppercase;

  background-color: $color-default-white;
  border-radius: size(4px);

  @include vp-1279 {
    margin-right: 12px;
    padding: 16px;

    max-width: 390px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 4px;
  }

  @include vp-767 {
    padding-top: 8px;
    padding-bottom: 8px;
    max-width: 100%;
    font-size: 14px;
  }

  @include vp-374 {
    padding-right: 12px;
    padding-left: 12px;
  }
}

.video-block__image-wrapper {
  overflow: hidden;
  width: 100%;
  min-height: size(504px);

  @include vp-1279 {
    min-height: 504px;
  }

  @include vp-767 {
    min-height: 352px;
  }

  @include vp-374 {
    min-height: 272px;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;

    transform: scale(1);

    transition: transform $trans-600;
  }
}

.video-block__cover {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: size(24px);
  width: 100%;
  height: 100%;

  @include vp-767 {
    padding: 16px;
  }
}

.video-block__logo-source {
  display: inline-flex;
  margin-right: auto;
  padding: size(8px);

  background-color: $color-default-white;

  svg {
    width: size(74px);
    height: size(16px);

    opacity: 1;

    transition: opacity $trans-600;
  }
}

.video-block__play {
  display: flex;
  align-items: center;
  padding: size(28px);
  height: 100%;
  background-color: $color-alizarin;
  border-radius: size(4px);

  svg {
    width: size(33px);
    height: size(33px);
  }

  @include vp-767 {
    padding: 16px;
  }

  @include vp-374 {
    display: none;
  }
}
