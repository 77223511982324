.tabs__item {
  width: 100%;
  left: 0;
}

.tabs__content {
  @include vp-1023 {
    margin-right: 24px;
    margin-left: 24px;

    overflow: hidden;
  }
}
