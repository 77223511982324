.slider-control {
  display: flex;
}

.slider-control__button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  margin-right: size(12px);
  padding: 0;

  width: size(48px);
  height: size(48px);

  color: $color-alizarin;

  background-color: $color-transparent;
  border: size(1px) solid $color-gainsboro;
  border-radius: size(8px);

  cursor: pointer;

  transition: border-color $trans-300;

  &:last-of-type {
    margin-right: 0;
  }

  svg {
    width: size(16px);
    height: size(16px);
    transform: scale(1);
    transition: transform $trans-300;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      border-color: $color-alizarin;
    }

    &:active {
      svg {
        transform: scale(1.25);
      }
    }
  }
}
