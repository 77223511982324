.page-footer {
  padding-top: size(40px);
  padding-bottom: 0;

  background-color: $color-night-rider;
  background-image: linear-gradient(65deg, $color-night-rider, transparentize($color-default-black, 0.5));
  background-size: size(3px) size(3px);
}

.page-footer__top {
  display: flex;
  padding-bottom: size(20px);

  @include vp-1279 {
    padding-bottom: 20px;
  }

  @include vp-1023 {
    flex-wrap: wrap;
    padding-bottom: 12px;
  }
}

.page-footer__logo {
  p {
    margin-bottom: 0;
    font-size: size(16px);
    line-height: size(20px);

    color: $color-default-white;
    max-width: size(560px);

    @include vp-1279 {
      max-width: 368px;
    }

    @include vp-1023 {
      margin-top: 0;
      max-width: 60%;
      text-align: end;
    }

    @include vp-767 {
      max-width: 100%;
      text-align: center;
    }
  }

  @include vp-1023 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  @include vp-767 {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    .logo {
      margin-bottom: 16px;
    }
  }
}

.page-footer__navigation {
  margin-top: size(20px);

  margin-left: auto;

  @include vp-1023 {
    padding-top: 12px;
    margin-left: 0;
    width: 100%;
    border-top: 1px solid transparentize($color-default-white, 0.5);
  }

  ul {
    display: grid;
    grid-template-columns: repeat(5, max-content);
    column-gap: size(20px);
    row-gap: size(8px);
    align-items: center;

    margin: 0;
    padding: 0;

    list-style: none;

    @include vp-767 {
      display: flex;
      row-gap: 0;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  li {
    display: flex;
    margin-right: size(20px);

    &:last-child {
      margin-right: 0;
    }

    a {
      font-size: size(20px);
      color: $color-default-white;

      transition: color $trans-300;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-alizarin;
        }

        &:active {
          opacity: 0.7;
        }
      }

      @include vp-1279 {
        font-size: 16px;
        line-height: 20px;
      }
    }

    @include vp-767 {
      margin-right: 0;

      a {
        padding-top: 8px;
        padding-bottom: 8px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.page-footer__bottom {
  display: flex;
  justify-content: space-between;

  padding-top: size(20px);
  padding-bottom: size(20px);

  border-top: size(1px) solid transparentize($color-default-white, 0.5);

  @include vp-1279 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @include vp-767 {
    flex-direction: column;
  }
}

.page-footer__copyright {
  max-width: 65%;

  @include vp-1023 {
    max-width: 75%;
  }

  @include vp-767 {
    max-width: 100%;
    margin-bottom: 20px;

    p {
      text-align: center;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: size(8px);
    font-size: size(12px);
    line-height: size(16px);

    color: transparentize($color-default-white, 0.5);

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.page-footer__dev {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  font-weight: 700;

  color: transparentize($color-default-white, 0.5);

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover,
    &:focus {
      span {
        &:last-of-type {
          color: $color-alizarin;
        }
      }
    }
  }

  span {
    display: inline-block;
    margin-bottom: size(8px);
    line-height: 12px;

    transition: color $trans-300;

    &:last-of-type {
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }

  @include vp-767 {
    flex-direction: row;
    justify-content: space-between;

    span {
      margin-bottom: 0;
    }
  }
}
