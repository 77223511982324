// используйте для скрытия нежелательных анимаций-скачков при поворотах экрана
@keyframes disableBreakpointAnimation {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}


@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(size(-3px));
  }

  100% {
    opacity: 0.6;
    transform: translateY(0);
  }
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes blur {
  0% {
    filter: blur(5px);
  }

  100% {
    filter: blur(0);
  }
}

@keyframes fade-in-bottom-50 {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-bottom-40 {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out-top-50 {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

@keyframes fade-in-bottom-20 {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out-top-20 {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes levitate {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes pulse {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.05);
  }
}

@keyframes flash {
  0% {
    left: -30px;
    margin-left: 0px;
  }

  30% {
    left: 110%;
    margin-left: 80px;
  }

  100% {
    left: 110%;
    margin-left: 80px;
  }
}
