.page-header {
  position: fixed;
  top: 0;
  z-index: 100;

  width: 100%;
  min-height: size(135px);

  @include vp-1023 {
    min-height: 99px;

    &.is-active {
      height: 100%;

      .page-header__main {
        padding-bottom: 40px;
        overflow-y: auto;
      }

      .page-header__main-nav {
        display: flex;
        margin-top: 40px;
      }

      .page-header__contacts {
        display: block;
      }

      .page-header__socials {
        display: flex;
      }
    }
  }
}

.page-header__top {
  padding-top: size(8px);
  padding-bottom: size(8px);

  background-color: $color-night-rider;
  background-image: linear-gradient(65deg, $color-night-rider, transparentize($color-default-black, 0.5));
  background-size: 3px 3px;

  p {
    margin-top: 0;
    margin-bottom: 0;

    font-size: size(16px);
    line-height: size(20px);

    color: transparentize($color-default-white, 0.1);
  }

  .container {
    display: flex;
    align-items: center;
  }

  @include vp-1023 {
    display: none;
  }
}

.page-header__main {
  padding-top: size(20px);
  padding-bottom: size(20px);

  background-color: $color-default-white;

  box-shadow: 0 3px 5px transparentize($color-default-black, 0.85);

  transition: max-height $trans-300;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .page-header__contacts {
    display: none;
  }

  @include vp-1023 {
    height: auto;

    .page-header__main-nav {
      display: none;
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.page-header__logo {
  display: flex;

  svg {
    width: size(200px);
    height: size(59px);
  }
}

.page-header__contacts {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  row-gap: size(8px);
  margin-left: auto;

  a {
    margin-right: size(56px);

    font-size: size(16px);
    line-height: size(16px);

    color: transparentize($color-default-white, 0.1);

    transition: color $trans-300;

    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: transparentize($color-default-white, 0.3);
      }

      &:active {
        color: transparentize($color-default-white, 0.4);
      }
    }
  }

  @include vp-1023 {
    margin-top: 40px;
    margin-bottom: 32px;
    margin-left: 0;

    p {
      margin-top: 0;
      margin-bottom: 12px;

      font-size: 16px;
      line-height: 20px;
    }

    a {
      display: flex;
      font-size: 16px;
      line-height: 20px;
      color: $color-night-rider;

      margin-bottom: 12px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  @include vp-767 {
    margin-top: 32px;
    margin-bottom: 10px;
  }
}

.page-header__socials {
  display: none;

  @include vp-1023 {
    margin-top: 24px;

    a {
      margin-right: 12px;
    }
  }
}

.page-header__toggle-menu {
  display: none;
  border: none;
  background-color: $color-transparent;
  color: $color-night-rider !important;

  @include vp-1023 {
    position: absolute;
    top: 27px;
    right: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 4px;

    width: 44px;
    height: 44px;

    svg {
      width: 28px;

      &:last-of-type {
        display: none;
      }
    }

    &.is-active {
      svg {
        &:first-of-type {
          display: none;
        }

        &:last-of-type {
          display: block;
        }
      }
    }
  }
}
