.main-nav__list {
  display: grid;
  grid-template-columns: repeat(5, max-content);
  column-gap: size(32px);
  row-gap: size(8px);
  align-items: center;

  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;

  list-style: none;

  @include vp-1023 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    column-gap: 0;
    row-gap: 0;
  }
}

.main-nav__item {
  &:last-child {
    margin-bottom: 0;
  }

  @include vp-1023 {
    margin-bottom: 12px;
  }

  @include vp-767 {
    margin-bottom: 8px;
  }
}

.main-nav__link {
  font-weight: 700;
  font-size: size(20px);
  line-height: size(24px);
  color: $color-night-rider;

  transition: color $trans-300;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-alizarin;
    }

    &:active {
      opacity: 0.7;
    }
  }

  &.is-active {
    color: $color-alizarin;
  }

  @include vp-1023 {
    font-size: 24px;
    line-height: 32px;

    text-transform: uppercase;
  }
}
