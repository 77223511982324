.contacts-block {
  position: relative;

  @include vp-1023 {
    padding-bottom: 32px;
  }
}

.contacts__list {
  margin: 0;
  padding: 0;

  list-style: none;

  background-color: $color-default-white;
}

.contacts__item {
  display: flex;
  flex-direction: column;

  margin-bottom: size(32px);

  &:last-child {
    margin-bottom: 0;
  }

  @include vp-1023 {
    margin-bottom: 32px;
  }

  h3 {
    margin-top: 0;
    margin-bottom: size(20px);

    display: flex;
    align-items: center;

    font-size: size(28px);

    svg {
      margin-right: size(20px);
    }

    @include vp-1279 {
      margin-bottom: 20px;
      font-size: 28px;

      svg {
        margin-right: 20px;
      }
    }

    @include vp-1023 {
      font-size: 20px;
      line-height: 24px;

      margin-bottom: 16px;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: size(12px);
    padding-left: size(44px);

    font-size: size(20px);
    line-height: size(24px);

    &:last-of-type {
      margin-bottom: 0;
    }

    @include vp-1279 {
      margin-bottom: 12px;
      padding-left: 44px;

      font-size: 20px;
      line-height: 24px;
    }

    @include vp-1023 {
      font-size: 16px;
      line-height: 20px;
    }
  }

  a {
    position: relative;

    display: flex;
    align-items: center;

    margin-bottom: size(12px);
    padding-left: size(44px);

    max-width: fit-content;

    font-size: size(20px);
    line-height: size(24px);

    transition: opacity $trans-300, color $trans-300;

    &:last-of-type {
      margin-bottom: 0;
    }

    svg {
      margin-right: size(16px);
    }

    @media (hover: hover),
    screen and (min-width: 0\0) {

      &:hover,
      &:focus {
        color: $color-alizarin;
      }
    }

    @include vp-1023 {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.contacts__map-wrapper {
  position: relative;
  margin-bottom: auto;
  width: 100%;
  height: 500px;

  border-radius: size(8px);
  overflow: hidden;

  &::before {
    content: "Подробнее";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;

    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    font-size: size(20px);
    color: $color-default-white;

    background-color: transparentize($color-default-black, 0.7);
    opacity: 0;

    cursor: pointer;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    display: none;
    width: 100%;
    height: 100%;

    background-image: url(".././img/content/map-preview.jpg");
    background-position: center;
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      &::before {
        opacity: 1;
      }
    }
  }

  &.is-hidden {
    &::before,
    &::after {
      display: flex;
    }
  }

  @include vp-1023 {
    margin-top: 32px;
    height: 400px;
  }

  @include vp-767 {
    height: 300px;
  }
}

.contacts__map {
  width: 100%;
  height: 500px;
  border-radius: size(8px);

  @include vp-1023 {
    height: 400px;
  }

  @include vp-767 {
    height: 300px;
  }
}
