.service-card {
  position: relative;

  padding: size(24px);

  min-height: size(240px);

  border-radius: size(8px);

  background-color: $color-steel-grey;

  overflow: hidden;
}

.service-card__description {
  max-width: 55%;

  @include vp-539 {
    position: relative;
    z-index: 3;
    max-width: 100%;
  }
}

.service-card__title {
  margin-top: 0;
  margin-bottom: size(16px);

  font-weight: 700;
  font-size: size(20px);
  line-height: size(24px);
  color: $color-default-white;

  @include vp-1279 {
    margin-bottom: 16px;

    font-size: 20px;
    line-height: 24px;
  }
}

.service-card__text {
  margin-top: 0;
  margin-bottom: size(8px);

  font-size: size(16px);
  line-height: size(20px);
  color: $color-default-white;
  opacity: 0.8;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include vp-539 {
    opacity: 1;
  }
}

.service-card__image-wrapper {
  position: absolute;

  @include vp-539 {
    opacity: 0.2;
  }
}

.service-card--switchboard {
  .service-card__image-wrapper {
    right: size(20px);
    top: size(40px);

    img {
      width: size(214px);
      height: size(240px);
    }

    @include vp-1279 {
      right: 4px;
      top: 40px;
    }

    @include vp-1023 {
      right: 20px;
      top: 20px;
    }
  }
}

.service-card--estimate {
  .service-card__image-wrapper {
    right: size(20px);
    top: size(20px);

    img {
      width: size(221px);
      height: size(286px);
    }

    @include vp-1279 {
      right: 20px;
      top: 20px;
    }
  }
}

.service-card--montage {
  .service-card__image-wrapper {
    right: size(-60px);
    top: 0;

    img {
      width: size(379px);
      height: size(274px);
    }

    @include vp-1279 {
      right: -92px;
    }

    @include vp-1023 {
      right: -80px;
    }
  }
}

.service-card--consultation {
  .service-card__image-wrapper {
    right: 0;
    bottom: 0;

    img {
      width: size(290px);
      height: size(224px);
    }
  }
}
