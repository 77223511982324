.category-product-card {
  position: relative;

  display: flex;
  flex-direction: column;

  padding: size(20px);

  min-height: size(280px);

  border-radius: size(8px);

  overflow: hidden;

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover,
    &:focus {
      .category-product-card__description {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  @include vp-1023 {
    min-height: 200px;
  }
}

.category-product-card__title {
  position: relative;
  z-index: 1;

  margin-top: auto;
  margin-bottom: 0;

  font-weight: 700;
  font-size: size(20px);
  line-height: size(24px);

  color: $color-default-white;

  @include vp-1279 {
    font-size: 16px;
    line-height: 20px;
  }
}

.category-product-card__description {
  position: absolute;
  left: 0;
  top: 0;

  padding: 20px;
  z-index: 1;

  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;

  opacity: 0;

  background-color: transparentize($color-default-black, 0.2);

  transform: translateY(100%);

  transition: transform $trans-300, opacity $trans-300;

  p {
    margin: 0;

    font-size: size(16px);
    line-height: size(20px);
    color: transparentize($color-default-white, 0.1);

    cursor: default;
  }
}

.category-product-card__image-wrapper {
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 60%;

    background-image: linear-gradient(180deg, $color-transparent, transparentize($color-default-black, 0.1));
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    pointer-events: none;
  }
}
