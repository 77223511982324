.intro-slider-pagination {
  display: flex;
  flex-wrap: wrap;
  max-width: size(492px);

  @include vp-1279 {
    max-width: 492px;
  }

  @include vp-767 {
    max-width: 288px;
  }
}

.intro-slider-pagination__button {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: size(12px);
  margin-bottom: size(12px);

  width: size(44px);
  height: size(44px);

  border-radius: size(8px);
  background-color: $color-transparent;
  color: $color-default-white;
  border: size(2px) solid $color-default-white;

  cursor: pointer;

  transition: color $trans-300, border-color $trans-300, opacity $trans-300;

  &:last-of-type {
    margin-right: 0;
  }

  svg {
    width: size(24px);
    height: size(24px);
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }

  &.is-active {
    color: $color-alizarin;
    border-color: $color-alizarin;
  }

  @include vp-1279 {
    margin-right: 12px;
    margin-bottom: 12px;

    width: 44px;
    height: 44px;

    border-radius: 8px;
    border: 2px solid $color-default-white;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  @include vp-767 {
    display: none;
  }
}
