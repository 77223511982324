.tabs-navigation__list {
  display: flex;

  margin: 0;
  padding: 0;

  list-style: none;
}

.tabs-navigation__item {
  position: relative;

  margin-right: size(16px);

  &:last-child {
    margin-right: 0;

    &::before {
      content: "";
      position: absolute;
      right: -24px;

      display: block;

      width: 24px;
      height: 24px;
    }
  }

  button {
    padding: size(12px) size(16px);

    font-size: size(14px);
    line-height: size(16px);
    color: $color-night-rider !important;

    background-color: $color-transparent;

    border: 1px solid $color-gainsboro;
    border-radius: size(8px);

    cursor: pointer;

    transition: border-color $trans-300, color $trans-300;

    white-space: nowrap;

    &.is-active {
      border-color: $color-night-rider;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        border-color: $color-night-rider;
      }

      &:active {
        opacity: 0.7;
      }
    }
  }
}
