.partner-card {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: size(232px);
  min-height: size(200px);

  background-color: $color-default-white;
  border-radius: size(8px);

  box-shadow: 0 size(2px) size(4px) transparentize($color-night-rider, 0.8);

  overflow: hidden;

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover,
    &:focus {
      .partner-card__description {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

.partner-card__image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 100%;

  svg {
    flex-shrink: 0;
    width: size(192px);
    height: size(80px);
  }
}

.partner-card__description {
  position: absolute;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: size(20px);

  width: 100%;
  height: 100%;

  background-color: transparentize($color-night-rider, 0);
  opacity: 0;

  transform: translateY(100%);

  transition: transform $trans-300, opacity $trans-300;

  p {
    margin: 0;

    font-size: size(16px);
    line-height: size(20px);
    color: transparentize($color-default-white, 0.1);
    text-align: center;

    cursor: default;
  }
}
