.logo {
  transition: opacity $trans-300;

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}
