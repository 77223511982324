.grid-container {
  display: grid;
  gap: size(24px);

  @include vp-1023 {
    gap: 16px;
  }
}

.grid-container--advantages {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-container--category-products {
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @include vp-1023 {
    grid-template-columns: 1fr 1fr;
  }

  @include vp-767 {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr))
  }
}

.grid-container--3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-container--category-services {
  grid-template-columns: 1fr 1fr;

  @include vp-1023 {
    grid-template-columns: repeat(auto-fill, minmax(470px, 1fr))
  }

  @include vp-767 {
    grid-template-columns: 1fr;
  }
}

.grid-container--portfolio {
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @include vp-1023 {
    display: flex;
    gap: 0;

    grid-template-columns: none;
  }
}

.grid-container--contacts-block {
  display: grid;
  grid-template-columns: 1fr 50%;
  gap: size(32px);

  @include vp-1279 {
    gap: 32px;
  }

  @include vp-1023 {
    display: flex;
    flex-direction: column;
  }
}

.grid-container--slider {
  display: flex;
}

.grid-container--scroll {
  @include vp-1023 {
    display: flex;
    padding: 0 24px;
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
