.advantages-item {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: size(16px);

  min-height: size(200px);

  background-color: $color-default-white;
  border-radius: size(8px);

  box-shadow: 0 size(2px) size(4px) transparentize($color-night-rider, 0.8);

  @include vp-1023 {
    min-width: 200px;
    border: 1px solid $color-gainsboro;

    box-shadow: none;

    &:last-of-type {
      &::before {
        content: "";
        position: absolute;
        right: -16px;

        width: 16px;
        height: 16px;
      }
    }
  }
}

.advantages-item__image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: size(16px);

  svg {
    width: size(120px);
    height: size(120px);
  }

  @include vp-1279 {
    width: 120px;
    height: 120px;
  }
}

.advantages-item__title {
  margin-top: 0;
  margin-bottom: 0;

  font-weight: 700;
  font-size: size(16px);
  line-height: size(20px);
  text-align: center;
  text-transform: uppercase;

  @include vp-767 {
    font-size: 12px;
    line-height: 16px;
  }
}
