.balloon {
  border-radius: size(8px);
}

.balloon__title {
  position: relative;

  margin-top: 0;
  margin-bottom: size(12px);
  font-family: $font-family--cera-pro;
  font-weight: 700;
  font-size: size(16px);
  text-transform: uppercase;
  line-height: size(20px);

  &::after {
    content: "";
    position: absolute;
    bottom: size(-8px);
    left: 0;

    width: 70%;
    height: size(2px);

    background-image: linear-gradient(90deg, $color-alizarin, $color-transparent);
    border-radius: size(2px);
  }
}

.balloon__contacts {
  p {
    margin-top: 0;
    margin-bottom: size(4px);
    font-family: $font-family--cera-pro;

    font-size: size(14px);
  }

  a {
    display: block;
    margin-bottom: size(4px);
    font-size: size(14px);

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
