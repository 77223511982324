.portfolio-card {
  position: relative;

  display: flex;
  flex-direction: column;

  min-height: size(200px);

  border-radius: size(8px);

  overflow: hidden;

  @include vp-1023 {
    min-height: auto;
  }
}

.portfolio-card__title {
  position: absolute;
  left: size(20px);
  right: size(20px);
  bottom: size(20px);

  margin: 0;

  font-weight: 700;
  font-size: size(20px);
  line-height: size(24px);

  color: $color-default-white;
}

.portfolio-card__image-wrapper {
  position: relative;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 60%;

    background-image: linear-gradient(180deg, $color-transparent, transparentize($color-default-black, 0.1));
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    pointer-events: none;
  }

  @include vp-1023 {
    width: auto;
    height: auto;
  }
}
