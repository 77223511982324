.intro {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.intro__wrapper {
  min-height: 100vh;
  min-height: calc(100 * var(--vh, 1vh));

  @include vp-767 {
    min-height: 568px;
  }
}

.intro__button-scroll {
  position: absolute;
  bottom: 4%;
  z-index: 2;

  left: 50%;
  right: 50%;
  color: $color-default-white;
  margin-right: auto;
  margin-left: auto;

  animation: fade-in-down 1.5s ease-in-out infinite;

  svg {
    width: size(32px);
    height: size(72px);
  }

  @include vp-1279 {
    svg {
      width: 32px;
      height: 72px;
    }
  }

  @include vp-1023 {
    display: none;
  }
}
