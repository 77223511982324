.animation-group {
  // fade-in-bottom
  .fib {
    opacity: 0;
  }

  // fade-in
  .fi {
    opacity: 0;
  }

  // blur
  .blur {
    filter: blur(10px);
  }

  .d-100 {
    animation-delay: 0.1s;
  }

  .d-200 {
    animation-delay: 0.2s;
  }

  .d-300 {
    animation-delay: 0.3s;
  }

  .d-400 {
    animation-delay: 0.4s;
  }

  .d-500 {
    animation-delay: 0.3s;
  }

  .d-600 {
    animation-delay: 0.6s;
  }

  .d-700 {
    animation-delay: 0.7s;
  }

  .d-800 {
    animation-delay: 0.8s;
  }

  .d-900 {
    animation-delay: 0.9s;
  }

  .d-1000 {
    animation-delay: 1s;
  }

  .d-1100 {
    animation-delay: 1.1s;
  }

  .d-1200 {
    animation-delay: 1.2s;
  }

  .d-1300 {
    animation-delay: 1.3s;
  }

  .d-1400 {
    animation-delay: 1.4s;
  }

  .d-1500 {
    animation-delay: 1.5s;
  }

  .d-1600 {
    animation-delay: 1.6s;
  }

  .d-1700 {
    animation-delay: 1.7s;
  }

  &.show {
    .fib {
      animation-name: fade-in-bottom-20;
      animation-duration: 0.6s;
      animation-timing-function: ease;

      animation-fill-mode: both;

      // @media (min-width: $tablet-width) {
      //   animation-name: fade-in-bottom-50;
      // }
    }

    .fi {
      animation-name: fade-in;
      animation-duration: 0.6s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .blur {
      animation-name: blur;
      animation-duration: 1s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }
  }
}
