.portfolio-slider {
  .slider-pagination {
    display: none;
  }

  @include vp-1023 {
    .slider-pagination {
      margin-top: 36px;

      display: flex;
      justify-content: center;
    }
  }
}

.portfolio {
  @include vp-1023 {
    .container {
      padding-right: 0;
      padding-left: 0;
    }

    .page-section__header {
      padding-right: 24px;
      padding-left: 24px;
    }
  }
}

.portfolio__tabs-navigation {
  margin-bottom: size(32px);

  @include vp-1023 {
    padding-left: 24px;
    padding-right: 24px;

    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
