.page-section {
  padding-top: size(104px);
  padding-bottom: size(104px);

  background-color: $color-default-white;

  @include vp-1023 {
    padding-top: 88px;
    padding-bottom: 88px;
  }

  @include vp-767 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

.page-section--smoke {
  background-color: $color-white-smoke;
}

.page-section--night {
  background-color: $color-night-rider;
  background-image: linear-gradient(65deg, $color-night-rider, transparentize($color-default-black, 0.5));
  background-size: size(3px) size(3px);

  .page-section__description {
    color: $color-default-white;
  }
}

.page-section__header {
  display: flex;

  margin-bottom: size(40px);
}

.page-section__description {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: auto;
  margin-left: auto;

  text-align: center;
  max-width: size(610px);

  p {
    font-size: size(20px);
    line-height: size(26px);

    opacity: 0.8;

    &:first-of-type {
      margin-top: size(28px);
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @include vp-767 {
    p {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.page-section__description--align-left {
  align-items: flex-start;
  margin-left: 0;
}

.page-section__title {
  margin-top: 0;
  margin-bottom: 0;

  font-weight: 700;
  font-size: size(36px);
  line-height: size(48px);

  text-transform: uppercase;

  @include vp-1279 {
    font-size: 36px;
    line-height: 48px;
  }

  @include vp-767 {
    font-size: 24px;
    line-height: 32px;
  }
}

.page-section__title--decor {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: size(-28px);
    left: 0;

    width: size(104px);
    height: size(8px);

    background-image: linear-gradient(90deg, $color-alizarin, $color-transparent);
    border-radius: size(2px);
  }

  @include vp-767 {
    text-align: start;
  }
}
