.btn {
  display: inline-flex;
  margin: 10px 0;
  padding: 10px 20px;

  color: $color-default-white;

  background-color: $color-neon-blue;
  border: none;
  cursor: pointer;

  transition: opacity $trans-default;

  &--red {
    background-color: $color-torch-red;
  }

  &:disabled {
    opacity: 0.5;

    pointer-events: none;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
}
