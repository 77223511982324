.intro-slider {
  position: absolute;
  top: size(135px);
  bottom: 0;
  left: 0;
  right: 0;

  .intro-slider-pagination {
    position: absolute;
    bottom: size(56px);
    left: auto;
    right: size(56px);
    z-index: 5;

    width: auto;
  }

  @include vp-1279 {
    top: 135px;

    .intro-slider-pagination {
      bottom: 56px;
      right: 56px;
    }
  }

  @include vp-1023 {
    top: 99px;
  }

  @include vp-374 {
    .intro-slider-pagination {
      display: none;
    }
  }
}

.intro-slider__list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.intro-slider__description {
  position: absolute;
  left: size(56px);
  z-index: 2;

  display: flex;
  flex-direction: column;

  margin-top: size(200px);
  max-width: 60%;
  height: 100%;

  @include vp-1279 {
    margin-top: 144px;
    left: 56px;
  }

  @include vp-1023 {
    max-width: 90%;

    left: 24px;
  }

  @include vp-374 {
    justify-content: flex-start;

    margin-top: 100px;
  }
}

.intro-slider__title {
  position: relative;

  margin-top: 0;
  margin-bottom: size(28px);

  font-weight: 700;

  font-size: size(44px);
  line-height: size(52px);
  color: $color-default-white;

  text-transform: uppercase;

  &::after {
    content: "";
    position: absolute;
    top: size(-40px);
    left: 0;

    width: size(256px);
    height: size(8px);

    background-image: linear-gradient(90deg, $color-alizarin, $color-transparent);
    border-radius: size(2px);
  }

  @include vp-1023 {
    font-size: 36px;
    line-height: 44px;
  }

  @include vp-767 {
    margin-bottom: 20px;

    font-size: 24px;
    line-height: 32px;
  }
}

.intro-slider__text {
  margin-top: 0;

  font-size: size(20px);
  line-height: size(28px);
  color: $color-default-white;

  letter-spacing: -0.015em;

  @include vp-1279 {
    font-size: 20px;
    line-height: 28px;
  }

  @include vp-767 {
    font-size: 16px;
    line-height: 20px;
  }
}

.intro-slider__image-wrapper {
  position: relative;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 100%;

    background-color: $color-default-black;

    opacity: 0.55;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    width: 100%;
    // height: 100%;
    // object-fit: cover;
  }
}
