.slider-pagination__button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;

  color: $color-night-rider;
  background-color: $color-transparent;

  border-radius: 8px;
  border: 1px solid $color-night-rider;

  opacity: 0.8;

  transition: color $trans-300, border-color $trans-300, opacity $trans-300;

  &:last-of-type {
    margin-right: 0;
  }

  &.is-active {
    color: $color-alizarin;
    border-color: $color-alizarin;
    opacity: 1;
  }

  @include vp-1023 {
    margin-right: 8px;

    width: 28px;
    height: 28px;
  }
}
