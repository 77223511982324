.navigation {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100vw;

  background-color: #ffffff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);

  &__list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 20px 60px;

    list-style: none;
  }

  &__link {
    position: relative;

    display: flex;
    padding: 10px;

    font-weight: 600;
    text-transform: uppercase;

    transition: color $trans-600;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: #0026ff;
      }
    }

    &.is-active {
      color: #0026ff;

      pointer-events: none;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;

      width: 100%;
      height: 2px;

      background-color: #0026ff;
      transform: translateX(-50%) scaleX(0);
      transform-origin: right;

      transition: transform $trans-600;
    }

    &.is-active::before {
      transform: translateX(-50%) scaleX(1);
      transform-origin: left;
    }

    &.is-inactive::before {
      display: none;
    }

    &.is-inactive {
      color: #000000;
      pointer-events: none;
    }
  }
}

.section-navigation {
  height: 75vh;
  padding: 20px 50px;

  &:nth-of-type(1) {
    background-color: #ff8686;
  }

  &:nth-of-type(2) {
    background-color: #f3ff86;
  }

  &:nth-of-type(3) {
    background-color: #86ffaa;
  }

  &:nth-of-type(4) {
    background-color: #8688ff;
  }

  &:nth-of-type(5) {
    background-color: #fb86ff;
  }
}
