.section-card {
  display: grid;
  column-gap: size(56px);
  grid-template-columns: 47% 1fr;

  @include vp-1279 {
    column-gap: 56px;
  }

  @include vp-1023 {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}

.section-card__content {
  grid-row: 1 / 3;
}

.section-card__header {
  grid-column: 2 / -1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: size(40px);
  padding-right: size(24px);

  max-width: size(504px);

  @include vp-1279 {
    padding-top: 40px;
    padding-right: 24px;

    max-width: 504px;
  }

  @include vp-1023 {
    margin-bottom: 40px;
    padding: 0;
  }
}

.section-card__title {
  margin-top: 0;
  margin-bottom: 24px;
  font-weight: 700;
  font-size: size(36px);
  line-height: size(48px);
  text-transform: uppercase;

  @include vp-1279 {
    font-size: 36px;
    line-height: 48px;
  }

  @include vp-767 {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
}

.section-card__title--decor {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: size(-20px);
    left: 0;

    width: size(104px);
    height: size(8px);

    background-image: linear-gradient(90deg, $color-alizarin, $color-transparent);
    border-radius: size(2px);

    @include vp-767 {
      display: none;
    }
  }
}

.section-card__text {
  margin-top: 0;
  margin-bottom: size(16px);
  font-size: size(16px);
  line-height: size(20px);
  color: transparentize($color-night-rider, 0.2);

  @include vp-767 {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }

  a {
    position: relative;
    transition: opacity $trans-300, color $trans-300;

    @media (hover: hover),
    screen and (min-width: 0\0) {

      &:hover,
      &:focus {
        color: $color-alizarin;

        &::before {
          background-color: $color-alizarin;
        }
      }
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.section-card__text--accent {
  font-weight: 700;
  color: $color-night-rider;
}

.section-card__footer {
  margin-top: size(12px);

  .section-card__links {
    margin-top: size(20px);

    @include vp-1279 {
      margin-top: 32px;
    }
  }

  @include vp-1023 {
    margin-top: 28px;
  }
}

.section-card__links {

  @include vp-767 {
    display: flex;
    justify-content: center;
  }

  a {
    display: inline-flex;
    margin-right: size(24px);

    padding-top: size(8px);
    padding-right: size(16px);
    padding-bottom: size(8px);
    padding-left: size(16px);

    min-height: size(40px);

    border-radius: size(8px);

    transition: opacity $trans-300;

    &:last-of-type {
      margin-right: 0;
    }

    &.youtube-button {
      background-color: $color-alizarin;

      svg {
        width: size(102px);
        height: size(22px);
      }
    }

    &.vk-button {
      background-color: $color-dodger-blue;

      svg {
        width: size(124px);
        height: size(22px);
      }
    }

    @include vp-1279 {
      margin-right: 24px;

      padding-top: 8px;
      padding-right: 16px;
      padding-bottom: 8px;
      padding-left: 16px;

      min-height: 40px;

      border-radius: 8px;
    }

    @include vp-767 {
      margin-right: 12px;
      padding-right: 12px;
      padding-left: 12px;
    }

    @media (hover: hover),
    screen and (min-width: 0\0) {

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }
}
