.preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: $color-default-white;

  transition: opacity $trans-600;

  @include vp-1023 {
    padding-right: 24px;
    padding-left: 24px;
  }

  @include vp-767 {
    padding-right: 16px;
    padding-left: 16px;
  }

  svg {
    width: size(750px);
    height: size(221px);

    opacity: 0;
    transition: opacity $trans-600;

    @include vp-1023 {
      width: 600px;
      height: 177px;
    }

    @include vp-767 {
      width: 250px;
      height: 74px;
    }
  }

  p {
    margin-top: size(72px);
    margin-bottom: 0;

    max-width: size(704px);

    font-size: size(20px);
    line-height: size(24px);
    text-align: center;

    opacity: 0;

    transition: opacity $trans-600 1s;

    @include vp-1023 {
      margin-top: 40px;
    }

    @include vp-767 {
      margin-top: 20px;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 20px;
    }

    @include vp-374 {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.is-loading {
    svg {
      opacity: 1;
    }

    p {
      opacity: 1;
    }
  }

  &.is-ready {
    opacity: 0;
  }

  &.is-hidden {
    display: none;
  }
}
