.advantages {
  padding-top: size(40px);
  padding-bottom: size(40px);

  @include vp-1023 {
    padding-top: size(40px);
    padding-bottom: size(40px);

    .container {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
